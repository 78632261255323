import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'memberLogin',
    loadChildren: () => import('./member-login/member-login.module').then( m => m.MemberLoginPageModule)
  },
  {
    path: 'usernamepassword',
    loadChildren: () => import('./username-password/username-password.module').then( m => m.UsernamePasswordPageModule)
  },
  {
    path: 'newMember',
    loadChildren: () => import('./new-member/new-member.module').then( m => m.NewMemberPageModule)
  },
  {
    path: 'securityPin',
    loadChildren: () => import('./security-pin/security-pin.module').then( m => m.SecurityPinPageModule)
  },
  {
    path: 'loginViaPin',
    loadChildren: () => import('./login-via-pin/login-via-pin.module').then( m => m.LoginViaPinPageModule)
  },   {
    path: 'securityQuestions',
    loadChildren: () => import('./security-questions/security-questions.module').then( m => m.SecurityQuestionsPageModule)
  },
  {
    path: 'successfullRegister',
    loadChildren: () => import('./successfull-register/successfull-register.module').then( m => m.SuccessfullRegisterPageModule)
  },
  {
    path: 'menu-page',
    loadChildren: () => import('./menu-page/menu-page.module').then( m => m.MenuPagePageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },  {
    path: 'authflow',
    loadChildren: () => import('./authflow/authflow.module').then( m => m.AuthflowPageModule)
  },
  {
    path: 'otpflow',
    loadChildren: () => import('./otpflow/otpflow.module').then( m => m.OtpflowPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthPageModule)
  },


  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
